<template>
  <div>
    <div class="content-header cols">
      <div>
        <h2>@todo {{ $tfo('name') }}</h2>
      </div>
      <div class="content-header-buttons">
        <el-button
          name="create-btn"
          class="header-button"
          circle
          icon="el-icon-plus"
          size="small"
          :disabled="$hasNoPermission(model.permissions.create)"
          @click="$router.push({ path: `/${model.route}/create/` })"
        />
      </div>
    </div>

    <el-table
      :name="model.name + '-table'"
      :class="model.name + '-table'"
      class="table-medium"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      @row-click="rowClick"
    >
      <el-table-column prop="id" :label="$tf('id')" width="60"></el-table-column>
      <el-table-column prop="name" :label="$tf('name')">
        <template slot-scope="{ row }">
          <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.name }}</router-link
          ><br />
          {{ row.comment }}
        </template>
      </el-table-column>
      <el-table-column prop="active" :label="$tf('active,,1')" width="120" align="center">
        <template slot-scope="{ row }">
          <div @click.stop class="text-center">
            <el-checkbox v-model="row.active" @change="(v) => activeChange(v, row)"> </el-checkbox>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'page-remote-monitoring',
  data: function () {
    return {
      model: {
        name: 'remote-monitoring',
        route: 'puppeteer/remote-monitoring',
        permissions: {
          create: 'ffsecurity.add_remote-monitoring'
        }
      }
    };
  },
  computed: {
    state() {
      return this.$store.state.puppeteer_remote_monitoring;
    }
  },
  mounted() {
    this.$store.dispatch(this.state.Action.Get);
  },
  methods: {
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      return `/${this.model.route}/` + encodeURIComponent(item.id) + '/';
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
      });
      return false;
    }
  }
};
</script>
